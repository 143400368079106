.navbar {
    min-height: 86px;
}

main {
    margin-top: 86px;
}

.footer {
    height: 80px;
    padding-top: 30px;
}

.kontakt {
    height: auto;
    padding-top: 30px;
    padding-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, .farbverlauf {
    font-weight: bold;
    background: linear-gradient(to right, var(--bs-info) 0%, var(--bs-dark) 30%, var(--bs-secondary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

span {
    color: var(--bs-info);
    font-weight: bold;
}

a {
    color: var(--bs-primary);
}

.nav-link:hover {
    color: var(--bs-secondary) !important;
    text-shadow: 0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary);
}

img {
    border-radius: 10px;
}

/* Home logo text */
.text-logo-container {
    position: relative;
    display: inline-block;
}
img.text-logo {
    display: block;
}
.text-logo-caption {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 50ch;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #600D3E;
    font-weight: normal;
}

@media screen and (max-width: 576px) {
    .text-logo-caption {
        top: 47%;
        width: 45ch;
        font-size: .75rem;
        line-height: .8rem;
    }
    .text-logo-caption p {
        margin: .6rem 0;
    }
}
@media screen and (max-width: 768px) {
    .text-logo-caption {
        font-size: .9rem;
        line-height: 1rem;
    }
}